import React from 'react';
import StandardLayout from '../../layouts/standard';
import pdf1 from '../../assets/solid-catalogue.pdf';

export default function SolidCatalogue(props) {
    return <StandardLayout title="Solid Catalogue">
        <div className="uk-section uk-container uk-margin">
        <embed src={pdf1} width="100%" style={{height: "80vh"}} type="application/pdf"/>

        </div>
    </StandardLayout>
}